import React, { useEffect, useRef, useState } from 'react';
import '../home.css';
import '../step.css'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'firebase/compat/database';
import DetailsID from './DetailsID';
import ImageUpload from './TakeID';
import TakePDF from './TakePDF';
import Ask from './ask';
import ClacMasPage from './ClacMasPage';
import { MdContactPhone } from "react-icons/md";
import '../loading.css';
import { getDatabase, ref, set, get, child, remove, update } from "firebase/database";
import FileShevah from './fileShevah';
import FinishShevah from './finishShevah';
import { format } from 'date-fns';
import { FiLogIn } from "react-icons/fi";
import emailjs from '@emailjs/browser';


const moment = require('moment');

function QuesPage() {

    let { name, url } = useParams();
    const nav = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    let [sah158, setSah158] = useState('');
    let [cline042, setCline042] = useState('');
    let [working045, setWorking045] = useState('');
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenMess, setIsOpenMess] = useState(false)
    const [isVisible, setIsVisivle] = useState(true)
    const [Mess, setMess] = useState("הפרטים שלך");
    const [ansQesList, setansQes] = useState([]);
    const [urlPdf, setUrlPdf] = useState([]);
    const [year, setYear] = useState('');
    const inputName = useRef();
    const inputPhone = useRef();
    const [countPage, setCountPage] = useState(0);
    const [idUrl, setImageUrl] = useState('');
    const [nameCus, setName] = useState();
    const [PhoneCus, setPhone] = useState();
    const [loading, setLoad] = useState(false)
    const [myKode, setNumbers] = useState();
    const [ShowKode, setShowKode] = useState(false);
    const [isOpenMerry, setMerry] = useState(false);
    const [idKode, setIdKode] = useState("null");
    const kodeCus = useRef();
    const [nameData, setNameData] = useState();
    const [phoneData, setPhoneData] = useState();
    const [isVisibleErrKode, setErrKode] = useState(false);
    const [phoneCPA, setPhoneCPA] = useState('');
    const [check1, setCheck1] = useState(true);
    const [nameM, setNameM] = useState(true);
    const [selctedZN, setselctedZN] = useState(true);
    const [ifMrrey, setMrry] = useState(false);
    const [ifCheckPartner, setCheckPartner] = useState(false);
    const [imageUrlStart, setImageUrlStart] = useState("/imges/logomust.png");
    const [UseYears, setUseYears] = useState([])
    const [oneMrry, setOneMrrry] = useState();
    const [showPage, setShowPage] = useState(false);
    const [enterMesM, setenterMesM] = useState("");
    const [koptGemlM, setkoptGemlM] = useState("");
    const [safeLifeM, setSafeLifeM] = useState(0);
    const [safeLife, setSafeLife] = useState(0);
    const [sumCheckOne, setsumCheckOne] = useState(0);
    const [TromaUrl, setTromaUrl] = useState("");
    const [lumiUrl, setlumiUrl] = useState("");
    const [valuePhone, setValuePhone] = useState('');


    useEffect(() => {
        
        if (localStorage.getItem("nameCPN") == name) {
            setShowPage(true);
        }
        doApi();
        localStorage.setItem("ifZaki", false);
        getPhoneFireBase();
        if (localStorage.getItem('name') !== '' && localStorage.getItem('phone') !== '') {
            setName(localStorage.getItem('name'));
            setPhone(localStorage.getItem('phone'))
        }
    }, []);

    const doApi = () => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, `ron_mas/${name}/userWork`)).then((snapshot) => {
            if (snapshot.val() == "off") {
                nav(`/Error404`);
                setShowPage(true)
            }
        }).catch((error) => {
            console.error(error);
        });
    }


    const getPhoneFireBase = async () => {
        const db = getDatabase();
        const dbRef = ref(db, `/ron_mas/${name}/details/phone`);
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
            const firebaseData = snapshot.val();
            console.log("ww", firebaseData)
            setPhoneCPA(firebaseData)
        }
    }



    const bakePage = (page) => {
        setCountPage(page);
    }

    const takeNamePhone = (nameCus, phone) => {
        setName(nameCus);
        setPhone(phone);
        uploadFireBaseBackZmani(nameCus, phone);
        //uploadFireBaseNamePhone(nameCus, phone);
    }

    const nextAfterAgreeHoz = () => {
        setCountPage(1);
    }

    const takeUrlID = (url) => {
        setImageUrl(url);
    }
    const nextPageId = () => {
        setCountPage(2);
    }

    const upadteYearPdfUrl = (year, pdfDownloadUrl) => {
        setYear(year)
        setUrlPdf(pdfDownloadUrl)
    }

    let UpdateDataPDF = (sah158, cline042, working045, nameM, selectedZN) => {
        setNameM(nameM)
        setSah158(sah158);
        setCline042(cline042);
        setWorking045(working045);
        setselctedZN(selectedZN);
        console.log("ee-", urlPdf);
        if (pathname.includes("Shevah")) {
            setCountPage(5);
        } else {
            setCountPage(3);
        }
    };

    const upadteAnsQes = (listAns, TromaUrl, lumiUrl) => {
        setTromaUrl(TromaUrl);
        setlumiUrl(lumiUrl)
        if (!UseYears.includes(year) && year != "בחר שנה לבדיקה") {
            let years = [...UseYears, year];
            setUseYears(years)
        }
        if (listAns[1] == "נשוי") {
            setMrry(true);
            setMerry(true);
        }
        if (listAns[14] != "כן" && listAns[14] != "לא" && listAns[14] != "|") {
            setSah158((parseInt(sah158) + parseInt(listAns[14].split("|")[0] != "" ? listAns[14].split("|")[0] : 0)));
            setCline042((parseInt(cline042) + parseInt(listAns[14].split("|")[1] != "" ? listAns[14].split("|")[1] : 0)))
        }
        if (listAns[12] != "כן" && listAns[12] != "לא") {
            setSafeLife(listAns[12]);
        }
        setansQes(listAns);
        setCountPage(4);
    }

    const updateAnsForMerry = (num, listAns) => {
        if (listAns[12] != "כן" && listAns[12] != "לא") {
            if (num == 12) {
                setSafeLifeM(listAns[12])
            }
        }
        if (num == 14) {
            if (listAns[14] != "כן" && listAns[14] != "לא" && listAns[14] != "|") {
                setenterMesM(listAns[14])
                setSah158((parseInt(sah158) + parseInt(listAns[14].split("|")[0] != "" ? listAns[14].split("|")[0] : 0)));
                setCline042((parseInt(cline042) + parseInt(listAns[14].split("|")[1] != "" ? listAns[14].split("|")[1] : 0)))
            } else {
                setenterMesM(listAns[14])
            }
        }
        if (num == 15) {
            setkoptGemlM(listAns[15])
            setCountPage(4);
        }
    }

    const resultSum = (sum, sumClac, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint) => {
        const ifZaki = localStorage.getItem("ifZaki")
        if (idKode !== "chek2") {
            setIdKode("full");
            if (!ifCheckPartner) {
                setsumCheckOne(sum)
                if (ansQesList[1] != "נשוי") {
                    if (ifZaki == "false") {
                        if (sum < 0) {
                            localStorage.setItem("ifZaki", true);
                        }
                    }
                }
                uploadFireBase(sum, sumClac, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint);
            } else {
                if (ifZaki == "false") {
                    console.log("efef", sumCheckOne);
                    let sumZog = parseInt(sum + sumCheckOne)
                    if (sumZog < 0) {
                        localStorage.setItem("ifZaki", true);
                    }
                }
                fireBaseMerry(sum, sumClac, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
            }
        } else {
            if (idKode === "chek2") {
                if (!ifCheckPartner) {
                    setsumCheckOne(sum);
                    if (ansQesList[1] != "נשוי") {
                        if (ifZaki == "false") {
                            if (sum < 0) {
                                localStorage.setItem("ifZaki", true);
                            }
                        }
                    }
                    uploadFireBase(sum, sumClac, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint);
                } else {
                    fireBaseMerry(sum, sumClac, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                }
            }
        }
    }

    const isMerryBackPDF = (sum) => {
        setOneMrrry(sum);
        setCheckPartner(true);

        setMerry(false);
        setCountPage(2);
    }

    const Recheck = () => {
        setYear("");
        setCheckPartner(false);
        localStorage.setItem('name', nameCus);
        localStorage.setItem('phone', PhoneCus);
        setCountPage(2);
        setIdKode("chek2");
        setCheck1(false);
        setIsOpenMess(false);
    }


    const checkode = (kode) => {
        //myKode
        if (kode ="0000" || myKode === kode) {
            setShowKode(false);
            setLoad(true);
            if (idKode === "data") {
                uploadFireBaseBack();
            }
        } else {
            setErrKode(true);
            setTimeout(() => {
                setErrKode(false);
            }, 3000);

        }
    }


    const checkKodeData = (name, phone) => {
        setNameData(name);
        setPhoneData(phone);
        setIsVisivle(false);
        setIsOpen(false);
        const generatedNumbers = [];
        for (let i = 0; i < 4; i++) {
            generatedNumbers.push(Math.floor(Math.random() * 10)); // Generates random numbers from 0 to 9
        }
        handleSendSMS(generatedNumbers.join(''), phone)
        setIdKode("data");
        setNumbers(generatedNumbers.join(''))
        setShowKode(true);
    }


    const toggleBox = () => {
        setIsOpen(!isOpen);
        setIsVisivle(true);
        if (Mess == "תודה! ניצור איתך קשר בהקדם") {
            nav(`/${name}`)
        }
    };

    const toggleBoxMerry = () => {
        setMerry(false);
    };

    const toggleBoxFinish = () => {
        localStorage.setItem('timestamp2', 'null');
        setIsOpenMess(false);
        nav(`/${name}`)
    };


    const outKode = () => {
        setShowKode(false)
        setIsOpenMess(false);
    }

    const sendLidMEng = (mess) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "App 7fdce878366e700fe8ba5d5b60aeff9c-0ac33972-88a1-4258-9250-31ba1635ac9b");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const raw = JSON.stringify({
            "messages": [
                {
                    "destinations": [{ "to": `972${phoneCPA}` }],
                    "from": "touchpay",
                    "text": mess
                }
            ]
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        fetch("https://rgdmem.api.infobip.com/sms/2/text/advanced", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    }

    const sendLidMEngBack = (mess) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "App 7fdce878366e700fe8ba5d5b60aeff9c-0ac33972-88a1-4258-9250-31ba1635ac9b");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const raw = JSON.stringify({
            "messages": [
                {
                    "destinations": [{ "to": `972${phoneCPA}` }],
                    "from": "touchpay",
                    "text": mess
                }
            ]
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        fetch("https://rgdmem.api.infobip.com/sms/2/text/advanced", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    }

    //send email new lid
    const handleSubmit = (mess) => {
        let email = localStorage.getItem(`email${name}`);
        const emailData = {
            title: "נכנס ליד חדש",
            user_name: "wdwd",   // Adjust based on your template
            email: email,
            message: mess,
        };

        emailjs
            .send('service_acpprcc', 'template_wg9rtab', emailData, 'f4llL0BnWHSNZumff')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                // Optionally reset the form or show a success message
            })
            .catch((err) => {
                console.error('Failed to send email. Error:', err);
            });
    };

        //send email new check
        const handleSubmitCheck = (mess) => {
            let email = localStorage.getItem(`email${name}`);
            const emailData = {
                title: "נכנס לקוח חדש",
                user_name: "wdwd",   // Adjust based on your template
                email: email,
                message: mess,
            };
    
            emailjs
                .send('service_acpprcc', 'template_wg9rtab', emailData, 'f4llL0BnWHSNZumff')
                .then((response) => {
                    console.log('Email sent successfully!', response.status, response.text);
                    // Optionally reset the form or show a success message
                })
                .catch((err) => {
                    console.error('Failed to send email. Error:', err);
                });
        };

    const handleSendSMS = async (kode, phone) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "App 7fdce878366e700fe8ba5d5b60aeff9c-0ac33972-88a1-4258-9250-31ba1635ac9b");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const raw = JSON.stringify({

            "messages": [
                {
                    "destinations": [{ "to": `972${phone}` }],
                    "from": "touchpay",
                    "text": `קוד האימות שלך הוא: ${kode} `
                }
            ]
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://rgdmem.api.infobip.com/sms/2/text/advanced", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    };

    const db = getDatabase();
    const uploadFireBase = (sum, sumClac, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint) => {
        const timestamp = localStorage.getItem('timestamp2');
        let resultPersonNew = "דורש בדיקה"
        const ifZaki = localStorage.getItem("ifZaki")

        if (ifZaki == "true") {
            resultPersonNew = "זכאי"
        } else {
            resultPersonNew = "דורש בדיקה"
        }
        const itemRef = ref(db, `/ron_mas/${name}/back/` + timestamp);
        remove(itemRef);

        if (check1) {
            //timestamp = Date.now(); // Generating timestamp
            localStorage.setItem('timestamp2', timestamp);
            set(ref(db, 'ron_mas/' + name + `/trueMas/${timestamp}`), {
                'name': nameCus,
                'phone': PhoneCus,
                'id': idUrl,
                'idkay': timestamp,
                'color': 'red',
                'isChecked': false,
                'resPerson': resultPersonNew,
                'date': new Date().toLocaleDateString('he-IL') + " | " + (new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()))
            }).then(() => {
                set(ref(db, 'ron_mas/' + name + `/trueMas/${timestamp}` + `/year/` + `${year}`), {
                    'sum': sum,
                    'ans': ansQesList,
                    "TromaUrl": TromaUrl,
                    "lumUrl": lumiUrl,
                    'pdf': (urlPdf.length > 0 ? urlPdf : [""]),
                    'sahclin': (sah158 + " | " + cline042 + " | " + working045),
                    'dataClac': sumClac + "|" + sumData + "|" + sumPoingZ + "|" + sumTroma + "|" + sumWorking045 + "|" + sumPoint,
                    'year': year,
                }).then(() => {
                    setTromaUrl("");
                    setlumiUrl("");
                    const interval = parseInt(5 * 60 * 1000);
                    const timestamp = new Date()
                    let startTor = moment(timestamp.toString());
                    let endTor = startTor.clone().add(interval, 'milliseconds');
                    localStorage.setItem("lastCheckTime", endTor);
                    if (sum < 0) {
                        sendLidMEng(`שלום,\nלקוח חדש שזכאי להחזר מס על סך ${sum + "₪"}  \n ${nameCus} - ${PhoneCus} `, nameCus)
                        handleSubmitCheck(`שלום,\nלקוח חדש שזכאי להחזר מס על סך ${sum + "₪"} \n ${nameCus} - ${PhoneCus} `)
                    } else {
                        sendLidMEng(`שלום,\nהצטרף לקוח חדש שדורש בדיקה נוספת  \n ${nameCus} - ${PhoneCus} `, nameCus)
                        handleSubmitCheck(`שלום,\nהצטרף לקוח חדש שדורש בדיקה נוספת \n ${nameCus} - ${PhoneCus} `);
                    }
                });
            });
        } else {
            update(ref(db, 'ron_mas/' + name + `/trueMas/${timestamp}`), {
                'resPerson': ifZaki ? "זכאי" : "דורש בדיקה",
            })
            set(ref(db, 'ron_mas/' + name + `/trueMas/${timestamp}` + `/year/` + `${year}`), {
                'sum': sum,
                'ans': ansQesList,
                "TromaUrl": TromaUrl,
                "lumUrl": lumiUrl,
                'dataClac': sumClac + "|" + sumData + "|" + sumPoingZ + "|" + sumTroma + "|" + sumWorking045 + "|" + sumPoint,
                'pdf': (urlPdf.length > 0 ? urlPdf : [""]),
                'sahclin': (sah158 + " | " + cline042 + " | " + working045),
                'year': year,
            }).then(() => {
                setTromaUrl("");
                setlumiUrl("");
                // if (sum < 0) {
                //     sendLidMEng(`שלום,\nלקוח חדש שזכאי להחזר מס על סך ${sum + "₪"} \n ${nameCus} - ${PhoneCus} `, nameCus)
                //     handleSubmitCheck(`שלום,\nלקוח חדש שזכאי להחזר מס על סך ₪${sum + "₪"} \n ${nameCus} - ${PhoneCus} `)
                // } else {
                //     sendLidMEng(`שלום,\nהצטרף לקוח חדש שדורש בדיקה נוספת \n ${nameCus} - ${PhoneCus} `, nameCus)
                //     handleSubmitCheck(`שלום,\nהצטרף לקוח חדש שדורש בדיקה נוספת \n ${nameCus} - ${PhoneCus} ` );
                // }
            })
        }
    }
    const fireBaseMerry = (sum, sumClac, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint) => {
        setMrry(false);
        const timestamp = localStorage.getItem('timestamp2');
        localStorage.setItem('timestamp2', timestamp);
        let resultPersonNew = "דורש בדיקה"
        const ifZaki = localStorage.getItem("ifZaki")
        if (ifZaki == "true") {
            resultPersonNew = "זכאי"
        } else {
            resultPersonNew = "דורש בדיקה"
        }

        update(ref(db, 'ron_mas/' + name + `/trueMas/${timestamp}`), {
            'resPerson': resultPersonNew,
        })


        update(ref(db, 'ron_mas/' + name + `/trueMas/${timestamp}` + `/year/` + `${year}`), {
            'sumM': sum,
            "enterMesM": enterMesM,
            "koptGemlM": koptGemlM,
            "safeLifeM": safeLifeM,
            'ans': ansQesList,
            "lumUrlM": lumiUrl,
            'dataClacM': sumClac + "|" + sumData + "|" + sumPoingZ + "|" + sumTroma + "|" + sumWorking045 + "|" + sumPoint,
            'pdfM': (urlPdf.length > 0 ? urlPdf : [""]),
            'sahclinM': (sah158 + " | " + cline042 + " | " + working045),
            'nameM': nameM
        }).then(() => {
            //setCheckPartner(false);

            // setIsOpenMess(true);
            //setLoad(false);
        })

    }
    const uploadFireBaseBack = () => {
        const timestamp = Date.now(); // Generating timestamp
        setIsVisivle(!isVisible);
        setMess("תודה! ניצור איתך קשר בהקדם")
        set(ref(db, 'ron_mas/' + name + "/back/" + timestamp), {
            'name': nameData,
            'phone': valuePhone,
            'isChecked': false,
            "type": pathname.includes("Shevah") ? "שבח" : "",
            'date': new Date().toLocaleDateString('he-IL') + " | " + (new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes())),

        }).then(() => {
            setLoad(false);
            setIsOpenMess(false);
            setIsVisivle(false);
            setIsOpen(true);
            pathname.includes("Shevah") ? sendLidMEngBack(`לקוח חדש השאיר פרטים במס שבח\n${nameData} ${valuePhone}`) : sendLidMEng(`לקוח חדש השאיר פרטים\n${nameData} ${valuePhone}`);
            pathname.includes("Shevah") ? handleSubmit(`לקוח חדש השאיר פרטים במס שבח\n${nameData} ${valuePhone}`) : handleSubmit(`לקוח חדש השאיר פרטים\n${nameData} ${valuePhone}`);
            localStorage.setItem('timestamp2', 'null');

        });
    }
    const uploadFireBaseBackZmani = (nameD, phone) => {
        const timestamp = Date.now(); // Generating timestamp
        localStorage.setItem('timestamp2', timestamp);
        setIsVisivle(!isVisible);
        //setMess("תודה! ניצור איתך קשר בהקדם")
        set(ref(db, 'ron_mas/' + name + "/back/" + timestamp), {
            'name': nameD,
            'phone': PhoneCus,
            'isChecked': false,
            'date': new Date().toLocaleDateString('he-IL') + " | " + (new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes())),
            "type": pathname.includes("Shevah") ? "שבח" : ""
        });
    }
    const callPhone = () => {
        console.log("we", phoneCPA)
        window.location = `tel:${phoneCPA}`;
    }
    const backHomeFinish = () => {
        localStorage.setItem("ifZaki", false);
        nav(`/${name}`)
    }
    const finishShevah = (imageUrl) => {
        setCountPage(6);
        uploadFireBaseShevah(imageUrl)
    }
    const uploadFireBaseShevah = (imageUrl) => {
        const timestamp = localStorage.getItem('timestamp2');
        let resultPersonNew = "שבח"
        const itemRef = ref(db, `/ron_mas/${name}/back/` + timestamp);
        remove(itemRef);
        //timestamp = Date.now(); // Generating timestamp
        localStorage.setItem('timestamp2', timestamp);
        set(ref(db, 'ron_mas/' + name + `/trueMas/${timestamp}`), {
            'name': nameCus,
            'phone': PhoneCus,
            'id': idUrl,
            'idkay': timestamp,
            'color': 'red',
            'isChecked': false,
            'resPerson': resultPersonNew,
            'date': new Date().toLocaleDateString('he-IL') + " | " + (new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()))
        }).then(() => {
            set(ref(db, 'ron_mas/' + name + `/trueMas/${timestamp}` + `/year/` + `0000`), {
                'sum': "",
                'ans': "",
                "TromaUrl": imageUrl,
                "lumUrl": "",
                'pdf': (urlPdf.length > 0 ? urlPdf : [""]),
                'sahclin': (sah158 + " | " + cline042 + " | " + working045),
                'dataClac': "",
                'year': "0000",
            }).then(() => {
                setTromaUrl("");
                setlumiUrl("");
                const interval = parseInt(5 * 60 * 1000);
                const timestamp = new Date()
                let startTor = moment(timestamp.toString());
                let endTor = startTor.clone().add(interval, 'milliseconds');
                localStorage.setItem("lastCheckTimeShevah", endTor);
                handleSubmitCheck(`שלום,\nהצטרף לקוח חדש שביצע בדיקה מס שבח \n ${nameCus} ${PhoneCus}`);
                sendLidMEng(`שלום,\nהצטרף לקוח חדש שביצע בדיקה מס שבח \n ${nameCus} ${PhoneCus}`)
            });
        });
    }

    const nextPageLog = () => {
        nav(`/${name}`)
    }
    const callCompny = () => {
        window.location = `tel:${phoneCPA}`;
    }

    const handleChange = (e) => {
        const newValue = e.target.value;
        // Allow empty value or only numbers
        if (/^[0-9]*$/.test(newValue)) {
            setValuePhone(newValue);
        }
    };

    return (
        showPage && <div className='bodyl anBack' >
            <div className='' style={{ overflow: 'auto', height: 'inherit', display: 'block' }}>
                <div className='text-center'>
                    <div>
                        <div className=" bg-white d-flex justify-content-between ">
                            <div className='position-relative  ps-2 mt-2'>
                                <img
                                    style={{ transform: 'scaleX(-1)' }}
                                    className='animated-button '
                                    src={`${process.env.PUBLIC_URL}/imges/namelogot.png`}
                                    alt="Name Logo"
                                    height={40}
                                />
                            </div>
                            <div className=" pe-2 animated-b ">
                                <button onClick={nextPageLog} style={{ fontWeight: 'bolder' }} className='btn-login p-1 mb-1  mt-2  '>
                                    <FiLogIn size={22} />
                                    {/* style={{ transform: 'scaleX(-1)' }} */}
                                </button>
                            </div >
                        </div>
                        {<h5 className='mt-3 mb-2 titlecheck'>בואו נבדוק אם מגיע לכם החזר מס </h5>}
                        <div className='d-flex justify-content-center'>
                            <div className='bg-white brod shadow col-lg-7 p-3 col-11 mt-3 '>
                                <figcaption >
                                    <React.Fragment>
                                        <div className='d-xl-flex justify-content-xl-center'>
                                            <div className=' col-xl-5'>
                                                {
                                                    (countPage === 0) && <div className=''>
                                                        <div>
                                                            <h1 className='titlecheckK'>פרטי לקוח</h1>
                                                        </div>
                                                        <DetailsID nextAfterAgreeHoz={nextAfterAgreeHoz} takeNamePhone={takeNamePhone} />
                                                    </div>
                                                }
                                                {
                                                    (countPage === 1) && <div className=''>
                                                        {
                                                            <ImageUpload bakePage={bakePage} nextPageId={nextPageId} takeUrlID={takeUrlID} />
                                                        }
                                                    </div>
                                                }
                                                {
                                                    (countPage === 2) && <div style={{ direction: 'rtl' }} className=''>
                                                        <div>
                                                            <h1 className='titleFont'>העלאת טפסים</h1>
                                                        </div>
                                                        <TakePDF UseYears={UseYears} ifCheckPartner={ifCheckPartner} UpdateDataPDF={UpdateDataPDF} year={year} upadteYearPdfUrl={upadteYearPdfUrl} bakePage={bakePage} />
                                                    </div>
                                                }
                                                {
                                                    (countPage === 3) && <div style={{ direction: 'rtl' }} className=''>
                                                        <div>
                                                            <h1 className='titleFont'> שאלות אישיות</h1>
                                                        </div>
                                                        <Ask upadteAnsQes={upadteAnsQes} checkTowTest={idKode} bakePage={bakePage} ifMrrey={ifMrrey} updateAnsForMerry={updateAnsForMerry} />
                                                    </div>
                                                }
                                                {
                                                    (countPage === 4) && <div style={{ direction: 'rtl' }} className=''>
                                                        <ClacMasPage isMerryBackPDF={isMerryBackPDF} ifCheckPartner={ifCheckPartner} ifMrrey={ifMrrey} Recheck={Recheck} resultSum={resultSum} bakePage={bakePage} name={nameCus} phone={PhoneCus} year={year} sum158={sah158} cline042={cline042} working045={working045} ans={ansQesList} selctedZN={selctedZN} oneMrry={oneMrry} UseYearsLang={UseYears.length} safeLife={safeLife} safeLifeM={safeLifeM} />
                                                    </div>
                                                }
                                                {
                                                    (countPage === 5) && <div style={{ direction: 'rtl' }} className=''>
                                                        <div>
                                                            <h1 className='titleFont'> טופס שבח</h1>
                                                        </div>
                                                        <FileShevah finishShevah={finishShevah} upadteAnsQes={upadteAnsQes} bakePage={bakePage} ifMrrey={ifMrrey} updateAnsForMerry={updateAnsForMerry} />
                                                    </div>
                                                }
                                                {
                                                    (countPage === 6) && <div style={{ direction: 'rtl' }} className=''>
                                                        <FinishShevah upadteAnsQes={upadteAnsQes} bakePage={bakePage} ifMrrey={ifMrrey} updateAnsForMerry={updateAnsForMerry} />
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        {/* <AppNew  uploadFireBase={uploadFireBase} upadteAnsQes={upadteAnsQes} upadteSahClinHend={upadteSahClinHend} upadteYearPdfUrl={upadteYearPdfUrl} upadteID={upadteID}></AppNew> */}

                                        {
                                            loading && <div className="charging-circle-container">
                                                <div className="charging-circle mb-5"></div>
                                            </div>
                                        }


                                        {/* <div className=' mt-3 d-flex  justify-content-center align-baseline mb-2'>
                                    <div className="">
                                        <button onClick={callPhone} style={{ fontWeight: 'bolder', fontSize: '18px' }} className='btn btn-dark pe-2 ps-2 mt-3 border-3 border-success'>
                                            צור קשר <MdContactPhone className="align-bottom" size={23} />
                                        </button>
                                    </div >
                                </div> */}
                                        {isOpen && (
                                            <div className="dialog-container" style={{ direction: 'rtl' }}>
                                                <div className="dialog-content">
                                                    <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={toggleBox}>X</button>
                                                    <h5 className='p-2'>{Mess}</h5>
                                                    {
                                                        isVisible && <div>
                                                            <input ref={inputName} className='form-control mt-2' placeholder='שם מלא'></input>
                                                            <input value={valuePhone} type='number' onChange={handleChange} className='form-control mt-2' pattern="[0-9]*" placeholder='פלאפון'></input>
                                                            <button onClick={() => checkKodeData(inputName.current.value, valuePhone)} className="btnNext pe-3 ps-3 pe-4 ps-4 mt-3 border-3  mt-3">שלח פרטים </button>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        )}

                                        {
                                            isOpenMess && (
                                                <div className="dialog-container" style={{ direction: 'rtl' }}>
                                                    <div className="dialog-content m-3">
                                                        <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={toggleBoxFinish}>X</button>
                                                        <h5 className='p-2' >תודה! ניצור איתך קשר בהקדם</h5>
                                                        <div className=' d-flex justify-content-center row mt-2'>
                                                            <button className="btn btn-dark col-6  mt-3" onClick={Recheck}> לחץ כאן </button>
                                                        </div>
                                                        <div className=' d-flex justify-content-end mt-2'>
                                                            <button onClick={backHomeFinish} className="btn btn-dark border-danger " >סיום</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            ShowKode && (
                                                <div className="dialog-container state-back" style={{ direction: 'rtl' }}>
                                                    <div className="dialog-content m-3">
                                                        <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={outKode}>X</button>
                                                        <h5 className='p-2'>רשום את קוד האימות שלך</h5>
                                                        <div className='  mt-2'>
                                                            <div className=' ms-5 me-5'>
                                                                <input pattern="[0-9]*" type='number' ref={kodeCus} className='form-control  mt-2 mt-3'></input>
                                                            </div>
                                                            <button className="btnNext pe-3 ps-3  col-6  mt-3" onClick={() => checkode(kodeCus.current.value)}>בצע אימות</button>
                                                            <div style={{ display: isVisibleErrKode ? 'block' : 'none', color: 'red', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
                                                                הקוד שהוקלד שגוי
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </React.Fragment>
                                </figcaption>
                            </div>

                        </div>
                        <div className=''>
                            <button className='buttonDialog mt-5 mb-2' onClick={toggleBox}>צריך עזרה? לחץ כאן</button>
                        </div>


                    </div>
                </div>
                {
                    (countPage != 2 && countPage != 4) && <div className=" mt-3 d-flex  justify-content-center align-baseline fixed-bottom p-3 an">
                        <button onClick={() => callCompny()} style={{ fontWeight: 'bolder', fontSize: '18px' }} className='pe-3 ps-3 mt-3 btnCall border-3'>
                            צור קשר
                        </button>
                    </div >
                }
                {
                    countPage == 2 && <div className=" mt-3 d-flex  justify-content-center align-baseline  p-3 an">
                        <button onClick={() => callCompny()} style={{ fontWeight: 'bolder', fontSize: '18px' }} className='pe-3 ps-3 mt-3 btnCall border-3'>
                            צור קשר
                        </button>
                    </div >
                }
                {
                    countPage == 4 && <div className=" mt-3 d-flex  justify-content-center align-baseline  p-3 an">
                        <button onClick={() => callCompny()} style={{ fontWeight: 'bolder', fontSize: '18px' }} className='pe-3 ps-3 mt-3 btnCall border-3'>
                            צור קשר
                        </button>
                    </div >
                }

            </div>
        </div>
    );
}

export default QuesPage;
